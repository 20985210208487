import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import { Durations } from '@/constants/durations'
import { slugs } from '@/constants/slugs'
import { LandingProvider } from '@/contexts/LandingContext'
import { RegionContextProvider } from '@/contexts/RegionContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Seo } from '@/layout/Seo'
import { getWebClient } from '@/services/ApolloClient'
import { WATCH_FOR_FREE_ENTRY_ID, getCollection } from '@/services/CmsService/Collection'
import { Faq, getFaqsForProjectByLocation } from '@/services/FaqService'
import { contentfulFaqLocations } from '@/services/FaqService'
import { getGuildHomeHero } from '@/services/LandingPageService'
import { GuildHomeHero, Collection, InvestmentBanner } from '@/types/codegen-contentful'
import { GetGuildMembershipCountQuery } from '@/types/codegen-federation'
import { getGenericStaticPaths } from '@/utils/GetGenericStaticPaths'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { GET_GUILD_MEMBERSHIP_COUNT } from '@/views/GuildCreateView/queries'
import { LandingView } from '@/views/LandingView'

interface HomeProps {
  faqs: Faq[]
  locale: string
  preview: boolean
  guildHomeHero: GuildHomeHero
  investmentBanner?: InvestmentBanner
  watchForFree: Collection
  members: number
}

const Home: NextPage<HomeProps> = ({
  faqs,
  locale,
  preview,
  guildHomeHero,
  investmentBanner,
  watchForFree,
  members,
}) => {
  const { t } = useTranslate('home')
  const url = `${getBasePath(locale)}`
  const title = t('angelStudiosHomeMetaTitle', 'Angel Studios: Stream Free, Original Shows for the Whole Family')
  const description = t(
    'angelStudiosHomeMetaDescription',
    'Get free unlimited access to film and TV series that amplify light through high quality fan-owned entertainment.',
  )

  return (
    <>
      <Seo canonical={url} description={description} openGraph={{ title, description, url }} path={''} title={title} />
      <Head>
        <meta name="insight-app-sec-validation" content="051f0772-9f39-4da7-b0e6-e47292cf6293"></meta>
      </Head>
      <LandingProvider guildHomeHero={guildHomeHero} watchForFree={watchForFree} members={members}>
        <ThemeContextProvider isDarkMode>
          <RegionContextProvider>
            <LandingView faqs={faqs} investmentBanner={investmentBanner} isLoggedIn={false} preview={preview} />
          </RegionContextProvider>
        </ThemeContextProvider>
      </LandingProvider>
    </>
  )
}

export const getStaticPaths = getGenericStaticPaths

export const getStaticProps: GetStaticProps = async ({ params, preview = false }) =>
  getStaticPropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const client = getWebClient()
    const locale = getLocaleFromParams(params)

    const guildHomeHero = await getGuildHomeHero()
    const investmentBanner = null // until next offering
    const watchForFree = await getCollection({ locale, preview }, WATCH_FOR_FREE_ENTRY_ID)
    const faqs = await getFaqsForProjectByLocation({
      location: contentfulFaqLocations.home,
      opts: { locale, preview },
      project: slugs.angelStudios,
    })
    const { data: members } = await client.query<GetGuildMembershipCountQuery>({
      query: GET_GUILD_MEMBERSHIP_COUNT,
      errorPolicy: 'all',
    })

    return {
      props: {
        faqs,
        locale,
        preview,
        guildHomeHero,
        investmentBanner,
        watchForFree,
        members: members?.guildMembershipCount,
        ...(await loadTranslations(locale, ['common', 'home', 'app-promo', 'account', 'guild'])),
      },
      revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
    }
  })

export default Home
